import React, { useState, useEffect } from 'react';
import { Grid, useTheme, Box, Paper, Skeleton, Typography, Switch, Button, Divider, Input, Slider as Muislider } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { Link } from 'react-router-dom';

import {loadStripe} from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
  LinkAuthenticationElement
} from '@stripe/react-stripe-js'

import {
  TextFieldComp,
  CreateFabOrButton,
  useAppContext,
  SliderComp,
} from 'clnt-common';

import {
  DonateIntro,
  myFetchAPI,

} from '#src';

//const phoneRegex = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
//const phoneRegex = /^[+]{1}(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/;
//const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const phoneRegex = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/* valid phone formats
   123 456 7890       - valid
   +1 123 456 7890    - valid

   123-456-7890       - valid
   +1-123-456-7890    - valid
   +91-123-456-7890   - valid

   (123) 456-7890     - valid

   +1 (123) 456-7890  - not valid
   +91 (123) 456-7890 - not valid

   123.456.7890       - not valid
   +1.123.456.7890    - not valid
   1.123.456.7890     - not valid
*/

let isBrowser = false;
if (typeof window !== 'undefined') {
  isBrowser = true;
}

/* FLOW
   1) Load /donate page > DonateDtls.tsx
   .  > UseEffect: get configpymt >> publishableKey
   .  > create stripe instance using publishableKey
   .  > capture amount 
   .  > cpature : email, phone, name
   .  > click continue (to show CC entry fields)

   2) Load <Payment > component
   .  > UseEffect: create-payment-intent to get clientSecret
   .  > Write out 'donation-initiated' to payments table (on server)
   .  > Includes <CheckoutForm> as child
   
   3) Load <CheckoutForm> component
   .  > Display CC fields
   .  > Upon submit: display any errors
   .  > Includes return_url which will be invoked after user successfully enters the CC data

   4) Load <CheckoutForm> after processing payment data which includes <Completion> component           
   .  > Retain the displayed CC data
   .  > Extract the payment id from URL
   .  > UseEffect: Reach out to stripe to get payment intent
   .  > UseEffect: 'donation-completed' to payments table (on server) 
   .  > Display success or error msg
 */


// // // // // // // // // // // // // // // // // // // // // // // // 
//  CheckoutForm   (Used from within <Payment/>)
// // // // // // // // // // // // // // // // // // // // // // // // 

function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const { email } = props;
  
  const { appState, dispatch } = useAppContext();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements || !isBrowser) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    
    const winLocOrigin = isBrowser ? window.location.origin : 'https://localhost';
    
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${winLocOrigin}/donate`,
	receipt_email: email,   // sends email upon successful payment (only in prod mode)
      },
    });
    
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }
    setIsLoading(false);
  }

  const paymentElementOptions = {
    layout: "accordion"
  }

  return (
    <form style={{
      border: '#F6F9FC solid 1px',
      borderRadius: '3px',
      padding: '8px',
      margin: '20px',
      boxShadow: '0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%)'
    }}
      onSubmit={handleSubmit}>

      <PaymentElement id="payment-element" options={paymentElementOptions}  />
      
      <button style={{
	backgroundColor: '#635BFF',
	borderRadius: '3px',
	color: 'white',
	border: 0,
	padding: '12px 16px',
	marginTop: '16px',
	fontWeight: 600,
	cursor: 'pointer',
	transition: 'all 0.2s ease',
	display: 'block',

	//&&:hover filter: 'contrast(115%)'
	
	//button:active {
	//transform: translateY(0px) scale(0.98);
	//filter: brightness(0.9);
	//}
	//button:disabled {
	//opacity: 0.5;
	//cursor: none;
	//}
      }} disabled={isLoading || !stripe || !elements} id="submit" >
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay Now"}
        </span>
      </button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

// // // // // // // // // // // // // // // // // // // // // // // // 
//  Completion
// // // // // // // // // // // // // // // // // // // // // // // // 

function Completion(props) {
  //  When the URL is: http://localhost:8009/donate?payment_intent=pi_3Q....&payment_intent_client_secret=pi_3Q....&redirect_status=succeeded
  //  Completion component will be rendered
  const { stripePromise } = props;

  const { appState, dispatch } = useAppContext();

  const [ errorMsg, setErrorMsg ] = useState('');
  const [paymentIntent, setPaymentIntent] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const host = appState.getEnvVar.API_SRVR_PYMT_URL;   // "https://apipymt-kripa-com.docker.localhost"

      if (!stripePromise || !isBrowser || paymentIntent) return;

      try {
	const winLoc = isBrowser ? window.location : 'https://localhost';
	const url = new URL(winLoc);
	const clientSecret = url.searchParams.get('payment_intent_client_secret');

	stripePromise.then(async (stripe) => {
	  const { error, paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

	  //console.log('DonateDtls:Completion() - paymentIntent: ', paymentIntent)
	  setPaymentIntent(paymentIntent);
	  setErrorMsg(error?.message || '');
	});
      }
      catch(e) {
	console.error('DonateDtls:Completion() - exception occured for retrievePymentIntent', e);
      }
    }
    fetchData();
  }, [stripePromise]);

  useEffect(() => {
    const host = appState.getEnvVar.API_SRVR_PYMT_URL;   // "https://apipymt-kripa-com.docker.localhost"

    async function fetchData() {
      if (!paymentIntent) return;

      try {
	await dispatch({type: 'SET_IO_BUSY', payload: true});
	const params = {
	  url: `${host}/pymtsvc?service=donation-completed`,
	  type: 'donation-completed',
	  token: appState.token,
	  fpid: appState.fpid,
	  reqAccountNo: appState.account?.accountNo,
	  body: {
	    amount: paymentIntent.amount / 100,
	    email: paymentIntent.receipt_email,
	    clientSecret: paymentIntent.client_secret,
	    paymentIntentId: paymentIntent.id,
	    donationStatus: paymentIntent.status,
	  }
	};
	const result = await myFetchAPI(appState.getEnvVar, params);
	await dispatch({type: 'SET_IO_BUSY', payload: false});
      }
      catch(e) {
	console.error('DonateDtls:Completion() - exception occured for donation-completed', e);
      }
    }
    fetchData();
  }, [paymentIntent]);

  //
  // Fragments
  //
  const errMsgHtml = (
    <p>
      {errorMsg}
    </p>
  );

  let okMsgHtml = null;
  if (paymentIntent) {
    okMsgHtml = appState.isDev ? (
      <>
      <h1>Thank you!</h1>
      <h3>Payment {paymentIntent.status}</h3>
      <h5>Please check your email for confirmation.</h5>
      <p style={{ backgroundColor: 'yellow'}}>Payment Id:&nbsp;&nbsp;&nbsp;
	<a style={{ backgroundColor: 'yellow', color: 'var(--accent-color)', fontWeight: '900' }} href={`https://dashboard.stripe.com/test/payments/${paymentIntent.id}`} target="_blank" rel="noreferrer">{paymentIntent.id}
	</a>
      </p>
      </>
    )
      :
      (
	<>
	<h1>Thank you!</h1>
	<h3>Payment {paymentIntent.status}</h3>
	<h5>Please check your email for confirmation.</h5>
	</>
      );
  }
  
  //
  // Render
  //
  return (
    <div style={{ display: paymentIntent ? 'block' : 'none' }} >
      {errorMsg ? errMsgHtml : okMsgHtml}
    </div>
  );
}

// // // // // // // // // // // // // // // // // // // // // // // // 
//  Payment
// // // // // // // // // // // // // // // // // // // // // // // // 

function Payment(props) {
  const { stripePromise, amount, email, setEmail, name, setName, phone, setPhone, warning, setWarning, app, appKey, plan } = props;
  const [ clientSecret, setClientSecret ] = useState('');

  const { appState, dispatch } = useAppContext();
  

  useEffect(() => {
    async function fetchData() {
      let result;
      const host = appState.getEnvVar.API_SRVR_PYMT_URL;   // "https://apipymt-kripa-com.docker.localhost"

      if (clientSecret) return;

      try {
	if (!(appState.token || appState.fpid)) {
	  console.warn(`Myrecalls:proces() - Suppress request as token & fpid do not exists`);
	}
	else {
	  dispatch({type: 'SET_IO_BUSY', payload: true});
	  const params = {
	    url: `${host}/pymtsvc?service=create-payment-intent`,
	    type: 'create-payment-intent',
	    token: appState.token,
	    fpid: appState.fpid,
	    reqAccountNo: appState.account?.accountNo,
	    body: {
	      amount,
	      app,
	      appKey,
	      plan,
	      email,
	      name,
	      phone,
	    }
	  };
	  result = await myFetchAPI(appState.getEnvVar, params);
	  dispatch({type: 'SET_IO_BUSY', payload: false});
	}
      }
      catch(e) {
	const msg = `Processing error: create-payment-intent failed`;
	console.error('DonateDtls:useEffect() - caught exception while making /create-payment-intent call', e);
	setWarning(msg);
	return;
      }

      if (!result) {
	console.error('DonateDtls:useEffect() - /create-payment-intent did not return results(clientSecret)', e);
	const msg = `Processing error: no response from create-payment-intent `;
	setWarning(msg);
	return;
      }

      // result: { code: 200, data: { clientSecret: 'xx' }, msg: 'OK', ok: true, type: 'cors' }
      if (result && result.code == '201' && result.data) {
        const data = result.data;
	if (data && data.clientSecret) {
	  setClientSecret(data.clientSecret);
	}
	else {
	  const msg = `Processing error: no clientSecret response from create-payment-intent`;
	  setWarning(msg);
	  return;
	}
      }
      else {
	// Invalid response
	const msg = `Processing error: invalid response from create-payment-intent`;
	setWarning(msg);
	return;
      }
    }

    fetchData();
  }, []);

  
  const appearance = {
    theme: appState.themeMode == 'dark' ? 'night' : 'stripe',  // 'stripe', 'night', 'flat'
    variables: {
      //colorPrimary: '#3bb05e',      // sets the payment options fg color
      //colorBackground: '#b88484',   // sets the form bg color (includes payment options on top, form, form entry fields' bg, DOES NOT INCL 'pay Now' button and surrounding frame color) 
    }
  };

  const loader = 'auto';

  const paymentAreaHtml = clientSecret && stripePromise ?
    (
      <>
      <h1>Payment</h1>
      <h2>Amount : $&nbsp;{amount}</h2>
      <Elements stripe={stripePromise} options={{ clientSecret, appearance, loader }}>
	<CheckoutForm email={email} />
      </Elements>
      </>
    )
    :
    null;
  
  return paymentAreaHtml;
}

// // // // // // // // // // // // // // // // // // // // // // // // 
//  DonateDtls
// // // // // // // // // // // // // // // // // // // // // // // // 

export { DonateDtls };
const DonateDtls = props => {
  const theme = useTheme();

  const app = 'Recalls';
  const appKey = 'RECL'; // This is used in statement desc   IE: 'KRIPA RECL 99'
  const plan = 'Recalls-Donate-99';    // This is used in statement desc
  let isPhoneValid = false, isEmailValid = false, isNameValid = false;
  let isDonateComplete = false;
  const settingsOnetime   = { presetAmount: 100, maxAmount: 1000, inputStep: 10, inputMin: 10, inputMax: 1000 };

  const { appState, dispatch } = useAppContext();
  const appStateName = appState?.user?.last ? appState?.user?.last + ', ' + appState?.user?.first : '';
  const appStateEmail = appState?.user?.email || '';
  const appStatePhone = appState?.user?.phone || '';
  
  const [ settings, setSettings ] = useState(settingsOnetime);
  const [ sliderAmount, setSliderAmount ] = React.useState(settings.presetAmount);
  const [ stripePromise, setStripePromise ] = useState(null);
  const [ isContinue, setIsContinue ] = useState(false);
  const [ email, setEmail] = useState(appStateEmail);
  const [ name, setName] = useState(appStateName);
  const [ phone, setPhone] = useState(appStatePhone);
  const [ warning, setWarning ] = useState('');
  
  useEffect(() => {

    async function fetchData() {
      const host = appState.getEnvVar.API_SRVR_PYMT_URL;  //"https://apipymt-kripa-com.docker.localhost"
      let result;

      try {
	dispatch({type: 'SET_IO_BUSY', payload: true});
	const params = {
	  url: `${host}/pymtsvc?service=configpymt`,
	  type: 'configpymt',
	  token: appState.token,
	  fpid: appState.fpid,
	  reqAccountNo: appState.account?.accountNo,
	  body: {
	  }
	};
	result = await myFetchAPI(appState.getEnvVar, params);
	dispatch({type: 'SET_IO_BUSY', payload: false});
      }
      catch(e) {
	const msg = `Processing error: configpymt failed`;
	console.error('DonateDtls:useEffect() - caught exception while making /configpymt call', e);
	setWarning(msg);
	return;
      }

      if (!result) {
	console.error('DonateDtls:useEffect() - /configpymt did not return results(publishableKey)', e);
	const msg = `Processing error: no response from configpymt `;
	setWarning(msg);
	return;
      }

      // result: { code: 200, data: { clientSecret: 'xx' }, msg: 'OK', ok: true, type: 'cors' }
      if (result && result.code == '200' && result.data) {
        const data = result.data;
	if (data && data.publishableKey) {
	  //console.log('DonateDtls:useEffect publishableKey : ' + data.publishableKey);
	  setStripePromise(loadStripe(data.publishableKey));
	}
	else {
	  const msg = `Processing error: no publishableKey response from configpymt`;
	  setWarning(msg);
	  return;
	}
      }
      else {
	// Invalid response
	const msg = `Processing error: invalid response from configpymt`;
	setWarning(msg);
	return;
      }
    };

    fetchData();
  }, []);

  //
  // Handlers
  //
  const handleSliderChange = (event, newValue) => {
    setSliderAmount(Number(newValue));
  };

  const handleInputChange = (event) => {
    let eventValue = event.target.value === '' ? settings.minAmount : Number(event.target.value)
    eventValue = (eventValue < settings.inputMin) ? settings.inputMin : eventValue;
    setSliderAmount(eventValue);
  };

  const handleInputBlur = () => {
    if (sliderAmount < 0) {
      setSliderAmount(settings.minAmount);
    }
    else {
      if (sliderAmount > settings.maxAmount) {
	setSliderAmount(settings.maxAmount);
      }
    }
  };

  const handleContinue = async () => {
    setIsContinue(true);
  };

  const handleEmail = async (e) => {
    const email = e.target.value;
    setEmail(email);
  }

  const handleName = (e, p, item, i) => {
    // NOTE: 'item' and 'i' will be null
    //console.log('onTFChange invoked for Change and Blur : ' + e.target.value);
    const name = e.target.value;
    setName(name);
  };

  const handlePhone = async (e) => {
    const phone = e.target.value;
    setPhone(phone);
  }

  const validateFields = () => {
    if (phone) {
      if (phoneRegex.test(phone)) isPhoneValid = true;
      else isPhoneValid = false;
    }
    else isPhoneValid = false;

    if(email) {
      if (emailRegex.test(email)) isEmailValid = true;
    else isEmailValid = false;
    }
    else isEmailValid = false;
    
    if (name) {
      const pos = name.indexOf(',');
      if (pos > 1 && pos < name.length-2) isNameValid = true;
      else isNameValid = false;
    }
    else isNameValid = false;

    //console.log(`DoanteDtls:validateFields phone: ${isPhoneValid}, email: ${isEmailValid}, name: ${isNameValid}`); 

    return isPhoneValid && isEmailValid && isNameValid;
  }

  //
  // conditions
  //

  const isValid = validateFields();
  if (isBrowser) {
    const winLoc = isBrowser ? window.location : 'https://localhost';
    const url = new URL(winLoc);
    const clientSecret = url.searchParams.get('payment_intent_client_secret');
    isDonateComplete = !!clientSecret;
  }
  const isDisabled = isContinue || isDonateComplete || !sliderAmount || !isValid;

  //
  // Fragments
  //

  const optionsSlider = {
    format: 'form',
    bucket: {
      p: null,
      id: 'slider',
      label: 'Slider',
      name: 'amount',

      color: "secondary",
      value: sliderAmount,
      size: "medium",
      handleChange: handleSliderChange,
      valueLabelDisplay: "on",
      step: settings.inputStep,
      min: settings.inputMin,
      max: settings.inputMax,
      ariaLabelledBy: 'input-slider',
      orientation: "horizontal",

      variant: 'filled',

      helperText: 'Enter Amount ($10 - $1,000)',
      usrStyle: {minWidth: 300},
    },
  };
  const amountSliderHtml = <SliderComp {...optionsSlider} />;

  const optionsTextFieldAmount = {
    format: 'form',
    bucket: {
      p: null,
      id: 'amount',
      label: 'Amount',
      name: 'amount',
      type: 'number',
      variant: 'filled',
      handleChange: handleInputChange,
      handleBlur: handleInputBlur,
      value: sliderAmount,
      helperText: 'Enter Amount ($10 - $1,000)',
      margin: 'normal',
      autoComplete: '',
      usrStyle: {width: '14rem'},
      adornStartIcon: <AttachMoneyIcon sx={{fontSize: '2.0rem', verticalAlign: 'bottom'}}/>,
      wgtProps: {
        step: settings.inputStep,
        min: settings.inputMin,
        max: settings.inputMax,
        type: 'number',
        'aria-labelledby': 'input-slider',
      },
    },
  };
  const amountTextHtml = <TextFieldComp {...optionsTextFieldAmount} />;

  const optionsTextFieldName = {
    format: 'form',
    bucket: {
      p: null,
      id: 'name',
      label: 'Name',
      name: 'name',
      type: 'text',
      variant: 'filled',
      handleChange: handleName,
      handleBlur: handleName,
      value: name,
      helperText: 'Enter Name (Last, First)',
      margin: 'normal',
      autoComplete: '',
      usrStyle: {width: '500px'},
      isDisabled: isContinue,
    },
  };
  const nameHtml = <TextFieldComp {...optionsTextFieldName} />; 
  const optionsTextFieldPhone = {
    format: 'form',
    bucket: {
      p: null,
      id: 'phone',
      label: 'Phone',
      name: 'phone',
      type: 'tel',
      variant: 'filled',
      handleChange: handlePhone,
      handleBlur: handlePhone,
      value: phone,
      helperText: 'Enter Phone (Format: +1-999-888-7777)',
      margin: 'normal',
      autoComplete: '',
      usrStyle: {width: '500px'},
      isDisabled: isContinue,
    },
  };
  const phoneHtml = <TextFieldComp {...optionsTextFieldPhone} />;
  const optionsTextFieldEmail = {
    format: 'form',
    bucket: {
      p: null,
      id: 'email',
      label: 'Email',
      name: 'email',
      type: 'email',
      variant: 'filled',
      handleChange: handleEmail,
      handleBlur: handleEmail,
      value: email,
      helperText: 'Enter Email',
      margin: 'normal',
      autoComplete: '',
      usrStyle: {width: '500px'},
      isDisabled: isContinue,
    },
  };
  const emailHtml = <TextFieldComp {...optionsTextFieldEmail} />;

  const warningHtml = warning ? <p>{warning}</p> : null;

  const paymentHtml = isContinue && sliderAmount ? 
  <Payment stripePromise={stripePromise}
    amount={sliderAmount}
    email={email} setEmail={setEmail}
    name={name} setName={setName}
    phone={phone} setPhone={setPhone}
    warning={warning} setWarning={setWarning}
    app={app} 
    appKey={appKey}
    plan={plan}
  />
    :
    null;

  const continueHtml = CreateFabOrButton('button', 'Continue', null, null, null, [0,0,0,0,0,0], handleContinue, isDisabled);

  const completionHtml = isDonateComplete ?
    <Box sx={{height: '480px', fontSize: '2rem'}}>
      <Completion stripePromise={stripePromise} />
    </Box>
    :
    null;
  
  //
  // Render Areas
  //

  // 
  const introAreaHtml = <DonateIntro />;

  // Amount Selection (Slider or Input)
  const amountAreaHtml = (
    <Box sx={{ width: 400, marginLeft: 'auto', marginRight: 'auto', marginTop: '4rem' }}>
      <Typography variant="h4" sx={{mt: '1rem', mb: '2rem'}}>
	Donation Amount
      </Typography>
      { amountSliderHtml }
      <br/>
      { amountTextHtml }
      <br/>
    </Box>
  );

  // entry area: name/phone/email
  const entryAreaHtml = (
    <>
    { nameHtml }
    <br/>
    { phoneHtml }
    <br/>
    { emailHtml }
    </>
  );

  //
  // Render Page
  //
  return (
    <Grid container spacing={2} data-where="DonateDtls" sx={{ width: '100%', padding: '8px'}}>

      {/* Intro Section */}
      <Grid item xs={12}>
	{ introAreaHtml }
      </Grid>

      {/* Entry Section */}
      <Grid item xs={12} sx={{ textAlign: 'center'}}>
	{ amountAreaHtml  }
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center'}}>
	{ entryAreaHtml }
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center'}}>
	{ warningHtml }
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center'}}>
	{ continueHtml }
      </Grid>


      {/* Pymt Section */}
      <Grid item xs={12} sx={{ textAlign: 'center'}}>
	{ paymentHtml }
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center'}}>
	{ completionHtml }
      </Grid>

    </Grid>
  );
};

/* Payment Intent (during Completion)
{
    "id": "pi_3QNg8DL0JESQwYiv0BV1XWYB",
    "object": "payment_intent",
    "amount": 10000,
    "amount_details": {
        "tip": {}
    },
    "automatic_payment_methods": {
        "allow_redirects": "always",
        "enabled": true
    },
    "canceled_at": null,
    "cancellation_reason": null,
    "capture_method": "automatic",
    "client_secret": "pi_3QNg8DL0JESQwYiv0BV1XWYB_secret_mskcrCReigbN9lXWN1UUiQD3k",
    "confirmation_method": "automatic",
    "created": 1732217617,
    "currency": "usd",
    "description": null,
    "last_payment_error": null,
    "livemode": false,
    "next_action": null,
    "payment_method": "pm_1QNg8fL0JESQwYivV3f8kNKR",
    "payment_method_configuration_details": {
        "id": "pmc_1PsAV3L0JESQwYivFRWkP5cn",
        "parent": null
    },
    "payment_method_types": [
        "card",
        "link"
    ],
    "processing": null,
    "receipt_email": "ygs.newsweek@kripa.com",
    "setup_future_usage": null,
    "shipping": null,
    "source": null,
    "status": "succeeded"
}
*/
