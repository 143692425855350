//import { getEnvVal } from '#src';
import { myFetch } from 'clnt-common';

const localCache = {
  consumers: null,
  consumersKey: null,
  alerts: null,
  alertsKey: null,
  search: null,
  searchKey: null,
  recent: null,
  recentKey: null,
  upap: null,
  upapKey: null,
  authenticate: null,
  authenticateKey: null,
  products: {},
};

export { myFetchAPI };

const myFetchAPI = async (getEnvVarJson, params) => {
  const { type, id, limit, skip, term, token, fpid, reqAccountNo, monthYear } = params;
  let getEnvVar = getEnvVarJson;
  let isSecure = false;   // true;   Jan 2023 - open up all features against default user/consumer
  let emertsURL;
  let method = 'GET';
  let monthYearBegin, monthYearEnd;
  if (monthYear) {
    let monthBeginReal = monthYear.month + 1; // 1-12
    let monthEndReal = monthYear.month + 1 + 1 === 13 ? 1 : monthYear.month + 1 + 1; // 1 (with next year), 2-12 (current year)
    let yearEndReal = monthEndReal === 1 ? monthYear.year + 1 : monthYear.year;

    monthBeginReal = monthBeginReal < 10 ? '0' + monthBeginReal : '' + monthBeginReal;
    monthEndReal = monthEndReal < 10 ? '0' + monthEndReal : '' + monthEndReal;

    //monthYearBegin = '"' + monthYear.year + '-' + monthBeginReal + '-' + '01"';
    monthYearBegin = `"${monthYear.year}-${monthBeginReal}-01"`;
    //monthYearEnd = '"' + yearEndReal + '-' + monthEndReal + '-' + '01"';
    monthYearEnd = `"${yearEndReal}-${monthEndReal}-01"`;
  }
  
  //console.log('myFetchAPI: token/fpid: ' + token + '/' + fpid);

  if (type === 'consumers') {
    if (localCache.consumers) {
      //console.log('myFetchAPI: Return consumers from cache');
      return localCache.consumers;
    }
    emertsURL = `${getEnvVar.CONSUMERS_SVC}`;
  } else if (type === 'alerts') {
    // http://localhost:3035/alerts?recallDate\[$gte\]="2020-02-01"&recallDate\[$lte\]="2020-02-31"&$limit=100&$skip=0&$sort\[recallDate\]=-1'
    // `${get EnvVar.ALERTS_SVC}&updatedAt[$gte]=${monthYearBegin}&updatedAt[$lte]=${monthYearEnd}&$limit=${limit}&$skip=${skip}&$sort[updatedAt]=-1&include=1`;
    //emertsURL = `${get EnvVar.ALERTS_SVC}&updatedAt[$gte]=${monthYearBegin}&updatedAt[$lt]=${monthYearEnd}&$limit=${limit}&$skip=${skip}&$sort[id]=1&include=1`;
    emertsURL = `${getEnvVar.ALERTS_SVC}&createdAt[$gte]=${monthYearBegin}&createdAt[$lt]=${monthYearEnd}&$limit=${limit}&$skip=${skip}&$sort[createdAt]=-1&$sort[cause]=-1&$sort[id]=-1&include=1`;

    /* User issued explicit vehicle/product checks get different alerts back. Can't cache them for returning full list.
       However, caching can be done for returning individual details.

       if (localCache.alerts && emertsURL === localCache.alertsKey) {
       console.log('myFetchAPI: Return alerts from cache');
       return localCache.alerts;
       }
     */
  } else if (type === 'search' && term && term.length) {
    emertsURL = `${getEnvVar.SEARCH_SVC}&term=${term}&$limit=${limit}&$skip=${skip}`; // sort option is added by the server

    if (localCache.search && emertsURL === localCache.searchKey) {
      //console.log('myFetchAPI: Return search from cache');
      return localCache.search;
    }
  } else if (type === 'recent') {
    emertsURL = `${getEnvVar.RECALLS_SVC}&recallDate[$gte]=${monthYearBegin}&recallDate[$lt]=${monthYearEnd}&$limit=${limit}&$skip=${skip}&$sort[recallDate]=-1&$sort[emertNo]=-1`;
    isSecure = false;

    if (localCache.recent && emertsURL === localCache.recentKey) {
      //console.log('myFetchAPI: Return recent from cache');
      return localCache.recent;
    }
    // http://localhost:3035/emerts?recallDate\[$gte\]="2020-02-01"&recallDate\[$lte\]="2020-02-31"&$limit=100&$skip=0&$sort\[recallDate\]=-1'
    //`${getEnvVar.RECALLS_SVC}&recallDate[$gte]=${monthYearBegin}&recallDate[$lte]=${monthYearEnd}&$limit=${limit}&$skip=${skip}&$sort[recallDate]=-1`;
  } else if (type === 'specificemert') {
    emertsURL = `${getEnvVar.RECALLS_SVC}/${id}`;
    isSecure = false;

    if (localCache.recent) {
      //console.log('myFetchAPI: Return specific emert from recent cache id #: ' + id);
      const entry = localCache.recent.data.data.find(item => {
        //console.log('match for item.id / id' + item.id + '/' + id);
        return item.id === Number(id);
      });
      //console.log('myFetchAPI: Return specific emert from recent cache ', entry);
      if (entry) return { data: entry };
    }

    /* Remeber format for alerts is different. 
       It has props from alerts table and then emert.xxxx and consumer.xxxx. 
       We extract out emert data by doing: find(for emert.id)  and then creating
       an emert object from all emert.xxxx properties    
     */
    // Function replicated from filterEmerts.jsx
    const extract = alert => {
      const emert = {};
      Object.keys(alert).forEach(key => {
        if (key.startsWith('emert.')) {
          if (key !== 'emert.createdAt')
            // prevent emert's createdAt overriding the alert's createdAt value.
            emert[key.split('.')[1]] = alert[key];
        }
        if (['cause', 'createdAt'].indexOf(key) !== -1) {
          emert[key] = alert[key];
        }
      });
      return emert;
    };

    if (localCache.alerts) {
      //console.log('myFetchAPI: Return specific emert from alerts cache id #: ' + id);
      const entry = localCache.alerts.data.data.find(
        item => Number(item['emert.id']) === Number(id),
      );
      //console.log('myFetchAPI: Return specific emert from alerts cache ', entry);
      if (entry) {
        const emert = extract(entry);
        return { data: { total: 1, data: [emert] } };
      }
    }

    if (localCache.search) {
      //console.log('myFetchAPI: Return specific emert from search cache id #: ' + id);
      const entry = localCache.search.data.data.find(item => item.id === Number(id));
      //console.log('myFetchAPI: Return specific emert from search cache ', entry);
      if (entry) return { data: entry };
    }
  } else if (type === 'upap') {
    emertsURL = `${getEnvVar.UPAP_SVC}`;

    if (localCache.upap) {
      //console.log('myFetchAPI: Return upap from cache');
      return localCache.upap;
    }
  } else if (type === 'authenticate') {
    emertsURL = `${getEnvVar.AUTHENTICATE_SVC}`;
    method = 'POST';
    isSecure = false;

    if (localCache.authenticate && localCache.authenticateKey === token || localCache.authenticateKey === fpid) {
      //console.log('myFetchAPI: Return authenticate from cache');
      return localCache.authenticate;
    }
  } else if (type === 'vehicheck') {
    emertsURL = `${getEnvVar.VEHICHECK_SVC}`;
    method = 'GET';
  } else if (type === 'products') {
    // car model/make
    if (id) {
      emertsURL = `${getEnvVar.PRODUCTS_SVC}&id=${id}`;
    } else emertsURL = `${getEnvVar.PRODUCTS_SVC}&make=${encodeURIComponent(term)}`;
    method = 'GET';

    if (localCache.products && localCache.products[emertsURL]) {
      //console.log('myFetchAPI: Return products from cache');
      return localCache.products[emertsURL];
    }
  } else if (type === 'keywordscheck') {
    emertsURL = `${getEnvVar.KEYWORDSCHECK_SVC}`;
    method = 'GET';
  } else if (type === 'create-payment-intent') {
    emertsURL = `${params.url}`;
    method = 'POST';
  } else if (type === 'donation-completed') {
    emertsURL = `${params.url}`;
    method = 'POST';
  } else if (type === 'configpymt') {
    emertsURL = `${params.url}`;
    method = 'GET';
  } else {
    return `Unsupporeted: TYPE=${type}, TERM=${term}`;
  }

  const options = {
    method: method,
  };

  if ( isSecure && !(token || fpid) ) {
    // Must have a token to make a secure requests
    // Return with an error
    console.info('myFetchAPI - Must supply an authentication token/fpid to make this request');
    return 'myFetchAPI - Must supply an authentication token/fpid to make this request';
  } else {
    if (token || fpid) {
      options.headers = {
	Authorization: `Bearer ${token ? token : fpid}`,
	'X-Fpid': fpid,
	'X-Reqaccountno': reqAccountNo || '',
	'X-CorrelationId': 'GetItFromAppState',
      };
    }
    if (type === 'authenticate') {
      if (!options.headers) options.headers = {};
      options.headers['Content-Type'] = 'application/json';
      options['body'] = JSON.stringify({ strategy: 'jwt', accessToken: token });
    }
    else
    if (type === 'create-payment-intent') {
      if (!options.headers) options.headers = {};
      options.headers['Content-Type'] = 'application/json';
      options['body'] = JSON.stringify({ ...params.body });
    }
    else
    if (type === 'donation-completed') {
      if (!options.headers) options.headers = {};
      options.headers['Content-Type'] = 'application/json';
      options['body'] = JSON.stringify({ ...params.body });
    }
    else
    if (type === 'configpymt') {
      if (!options.headers) options.headers = {};
      options.headers['Content-Type'] = 'application/json';
      //options['body'] = JSON.stringify({ ...params.body });
    }

    let resp;
    try {
      resp = await myFetch(emertsURL, options);
    }
    catch (e) {
      console.error('myFetchAPI() - caught exception while making myFetch() call', e);
    }

    // resp: { code: 401, data: {}, msg: 'Unauthorized' }
    // resp.data: { className: "not-authenticated", code: 401, data: {}, errors: {}, message: "jwt expired", name: "NotAuthenticated" }
    // resp.data.data: {name: "TokenExpiredError", message: "jwt expired", expiredAt: "2020-03-13T19:23:31.000Z"}

    if (resp.code === 401) {
      if (resp.data && resp.data.message === 'jwt expired') {
        console.info('myFetchAPI: jwt expired', resp);
        //alert('Please SIGN IN again. Your session expired');
      } else {
        console.info('myFetchAPI: unauthorized', resp);
        // alert('Request Unauthorized.');  LET THE CALLER HANDLE THE EXCEPTION
      }
      // navigate('/');   for emerts we allow fetching even if user is not logged in ... only for the last month and current month
    } else if (resp.code === 999) {
      console.info(
        'myFetchAPI: backend connection failed > SUPPRESS redirect to /home --- resp: ',
        resp,
      );
      //navigate('/');
    }

    // Manage Local Cache so that individual request can be found in the cache
    if (resp && resp.code !== 401 && resp.data) {
      //console.log('myFetchAPI - manage cache ', resp);

      if (type === 'consumers') localCache.consumers = resp || null;

      /*
	 User issued explicit vehicle/product checks get different alerts back. Can't cache them for returning full list.
	 However, caching can be done for returning individual details.
       */
      if (type === 'alerts' && resp && resp.data && resp.data.data && resp.data.data.length) {
        localCache.alerts = resp;
        localCache.alertsKey = emertsURL;
      }

      if (type === 'search' && resp && resp.data && resp.data.data && resp.data.data.length) {
        localCache.search = resp;
        localCache.searchKey = emertsURL;
      }
      if (type === 'recent' && resp && resp.data && resp.data.data && resp.data.data.length) {
        localCache.recent = resp;
        localCache.recentKey = emertsURL;
      }
      if (type === 'upap' && resp && resp.data && resp.data.user && resp.data.user.id) {
        localCache.upap = resp;
        //localCache.upapKey = emertsURL;
      }
      if (
        type === 'products' &&
          resp &&
          resp.data.total &&
          resp.data.data &&
          resp.data.data.length
      ) {
        const entries = Object.keys(localCache.products);
        if (entries.length > 20) return; // place an upper limit on number of cache entries for products
        localCache.products[emertsURL] = resp;
        //localCache.upapKey = emertsURL;
      }
      if (type === 'authenticate' && resp) {
        localCache.authenticate = resp || null;
        localCache.authenticateKey = token || fpid;
      }
    }
    //console.log('myFetchAPI - returning resp ========== 2E =========== ', resp);
    return resp;
  }
};

